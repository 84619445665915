






























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import LiebiaoListComponent from '@/components/LiebiaoListComponent.vue';
import VocabularyListComponent from '@/components/VocabularyListComponent.vue';
import TagsComboBox from '@/components/shared/TagsComboBox.vue';
import QuizEntryCard from '@/components/shared/QuizEntryCard.vue';
import { Authenticator } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter, LiebiaoManager, QuizService } from '@/services';
import { AxiosResponse } from 'axios';
import { UserInfo, EmptyPostPayload, Liebiao, Nullable, NavFromTo, LiebiaoRequest, LiebiaoResult, Vocabulary, QuizEntry, QuizResumeResult, QuizSummaryRequest } from '@/types';
import { NewQuizData, NewQuizResult, QuizEntryResult, QuizEntryRequest, QuizSummaryResult, QuizEntryVerdict, VocabularyStatus, Tag, QuizSettings, QuizSummaryEntryVerdict } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT, QUIZ_SELECTION_OPTION } from '@/enums';
import { Route, RouteConfig, Location } from 'vue-router';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { generateRequestID, formatToUTC } from '@/helpers/helpers';
import LiebiaoComboBox from '@/components/shared/LiebiaoComboBox.vue';
import { getStatusString, getStatusColor, getStatusTitle } from '@/helpers/StatusHelpers';
import StatusComboBox from '@/components/shared/StatusComboBox.vue';
import { log } from '@/helpers/logger';

type QuizOption = {id: QUIZ_SELECTION_OPTION, title: string, disabled: boolean};
type QuizMode = "selection" | "preparing" | "quiz" | "results" | "start" | "loading";

@Component({
  components: {
    AsyncButton, LiebiaoListComponent, VocabularyListComponent, LiebiaoComboBox, TagsComboBox, QuizEntryCard, StatusComboBox
  }
})
export default class QuizView extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.LIEBIAO_MANAGER_INSTANCE)
  private liebiao_manager!: LiebiaoManager;
  
  @lazyInject(TYPES.QUIZ_SERVICE_INSTANCE)
  private quiz_service!: QuizService;

  public formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US'); 

  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.QUIZ;
  private nav_event_subscription: Nullable<Subscription> = null;
  public quiz_types: Array<QuizOption> = [
    {id: QUIZ_SELECTION_OPTION.DAILY, title: 'Daily Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WEEKLY, title: 'Weekly Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_10, title: '10 Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_20, title: '20 Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_50, title: '50 Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_100, title: '100 Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_150, title: '150 Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_200, title: '200 Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.WORDS_300, title: '300 Vocabulary', disabled: false},
    //{id: QUIZ_SELECTION_OPTION.RANDOM, title: 'Random vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.RECENTLY_ADDED, title: 'Recently Added Vocabulary', disabled: false},
    {id: QUIZ_SELECTION_OPTION.DIFFICULT, title: 'Difficult Vocabulary', disabled: false},
  ];
  public quiz_settings: QuizSettings = {
    reverse_mode: false,
    audio_mode: false
  };
  public quiz_mode: QuizMode = "selection";
  public quiz_id: number = 0;
  public total: number = 0;
  public quiz_index: number = 1;
  public current_vocabulary: Nullable<QuizEntry> = null;
  public next_vocabulary: Nullable<Promise<QuizEntryResult>> = null;
  public entry_verdict: Nullable<QuizEntryVerdict> = null;
  //public audio_first: boolean = false;
  //public reverse_mode: boolean = false;
  public loading_next_entry: boolean = false;
  public quiz_summary: Nullable<QuizSummaryResult> = null;
  public quiz_liebiaos: Array<Liebiao> = new Array<Liebiao>(); 
  public quiz_tags: Array<Tag> = new Array<Tag>();

  public date_from: string = "";
  public date_to: string = "";
  public modal_from_date: boolean = false;
  public modal_to_date: boolean = false;
  public quiz_counts: Array<string> = ["10", "20", "50", "100", "150", "200", "300"];
  public filtered_quiz_counts: Array<string> = ["All", "10", "20", "50", "100", "150", "200", "300"];
  public filtered_quiz_count: string = "All";
  public random_quiz_count: string = "100";
  public random_quiz: boolean = false;
  public filtered_quiz: boolean = true;
  public statuses: Array<VocabularyStatus> = new Array<VocabularyStatus>();

  public recently_quizzed: Array<QuizSummaryEntryVerdict> = new Array<QuizSummaryEntryVerdict>();
  public recently_quized_loading: boolean = true;

  @Watch('random_quiz')
  onRandomQuizSelected(): void {
    if(this.random_quiz) this.filtered_quiz = false;
  }
  
  @Watch('filtered_quiz')
  onFilteredQuizSelected(): void {
    if(this.filtered_quiz) this.random_quiz = false;
  }

  @Watch('quiz_mode')
  onQuizModeChanged(): void {
    if(this.quiz_mode == "selection") {
      this.loadRecentlyQuizzed();
    }
  }

  statusColor(status: VocabularyStatus): string {
    return getStatusColor(status);
  }
  
  statusTitle(status: VocabularyStatus): string {
    return getStatusTitle(status);
  }

  changeAudioMode(): void {
    if(this.quiz_settings.audio_mode) this.quiz_settings.reverse_mode = false;
  }
  
  changeReverseMode(): void {
    if(this.quiz_settings.reverse_mode) this.quiz_settings.audio_mode = false;
  }

  saveFromDate(date: string): void {
    if(this.$refs.dialog_from_date) {
      (this.$refs.dialog_from_date as any).save(date);
    }
  }

  saveToDate(date: string): void {
    if(this.$refs.dialog_to_date) {
      (this.$refs.dialog_to_date as any).save(date);
    }
  }

  goBack(): void {
    const quiz_view: Location = {
      name: SHENGCI_MODULES.QUIZ,
      replace: true
    };
    this.nav_router.navigateToIfPossible(quiz_view);
  }
  
  quiz_verdict(): string {
    if(this.quiz_summary) {
      const percent: number = this.quiz_summary.right / this.quiz_summary.total;
      if(percent == 1) return "Perfect score!";
      if(percent >= .9) return "Nicely done!";
      if(percent >= .6) return "Not too bad!";
      if(percent >= .4) return "Don't stop studying!";
      if(percent == 0) return "Get your head in the game!";
      return "Keep studying!";
    }
    return "Keep studying!";
  }

  startQuiz(): void {
    this.quiz_mode = "quiz";
    this.quiz_index = 1;
    const quiz_view: Location = {
      name: SHENGCI_MODULES.QUIZ,
      params: {quiz_id: this.quiz_id.toString()},
      replace: true
    };
    this.nav_router.navigateToIfPossible(quiz_view);
    this.loadEntry(this.quiz_index + 1);
  }

  nextEntry(result: boolean): void {
    if(this.current_vocabulary) {
      this.entry_verdict = {
        entry_id: this.current_vocabulary.vocabulary.id,
        verdict: result,
        quiz_index: this.quiz_index
      };
    }

    if(this.next_vocabulary) {
      //loading indicator logic for async button would go here
      //this.loading_next_entry = true;
      this.next_vocabulary.then( (next_vocabulary_result: QuizEntryResult) => {
        this.quiz_index = Math.min(this.total, this.quiz_index + 1);
        if(next_vocabulary_result.entry) {
          this.current_vocabulary = next_vocabulary_result.entry;
        }
        if(this.quiz_index + 1 <= this.total) {
          this.loadEntry(this.quiz_index + 1);
        } else {
          this.loadEntry();
          this.next_vocabulary = null;
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      const summary_request: QuizSummaryRequest = {
        quiz_id: this.quiz_id
      };
      if(this.entry_verdict) {
        summary_request.entry_verdict = this.entry_verdict;
      }
      this.quiz_service.getQuizSummary(summary_request).then( (quiz_summary: QuizSummaryResult) => {
        this.quiz_mode = "results";
        this.quiz_summary = quiz_summary;
      });
    }
  }

  loadEntry(entry_index?: number): void {
    const quiz_entry_request: QuizEntryRequest = {
      quiz_id: this.quiz_id,
    };
    if(entry_index) quiz_entry_request.fetch_index = entry_index;
    if(this.quiz_index == 1) {
      //send this only on the second request. its an option that was selected after quiz creation
      quiz_entry_request.reverse_mode = this.quiz_settings.reverse_mode;
      quiz_entry_request.audio_mode = this.quiz_settings.audio_mode;
    }
    if(this.entry_verdict) {
      quiz_entry_request.entry_verdict = this.entry_verdict;
    }
    this.next_vocabulary = this.quiz_service.getEntry(quiz_entry_request);
  }

  quickQuiz(quiz_type: QUIZ_SELECTION_OPTION): void {
    this.quiz_mode = "preparing";
    this.quiz_service.create({selection: quiz_type}).then((new_quiz_result: NewQuizResult) => {
      this.reset();
      //console.log(new_quiz_result);
      this.quiz_id = new_quiz_result.quiz_id;
      this.total = new_quiz_result.total;
      this.quiz_mode = "start";
      this.current_vocabulary = new_quiz_result.first;
    });
  }

  customQuiz(): void {
    this.quiz_mode = "preparing";
    const quiz_data: NewQuizData = {};
    quiz_data.count = this.random_quiz ? this.random_quiz_count : this.filtered_quiz_count;
    if(this.quiz_tags.length > 0) {
      quiz_data.tags = [];
      this.quiz_tags.forEach((tag: Tag) => {
        quiz_data.tags?.push(tag.id);
      });
    }
    if(this.quiz_liebiaos.length > 0) {
      quiz_data.liebiaos = [];
      this.quiz_liebiaos.forEach((liebiao: Liebiao) => {
        quiz_data.liebiaos?.push(liebiao.id);
      });
    }
    if(this.statuses.length > 0) {
      quiz_data.statuses = [];
      this.statuses.forEach((status: VocabularyStatus) => {
        quiz_data.statuses?.push(status);
      });
    }
    if(this.date_from) quiz_data.date_from = formatToUTC(this.date_from).toISOString();
    if(this.date_to) quiz_data.date_to = formatToUTC(this.date_to).toISOString();

    this.quiz_service.create(quiz_data).then((new_quiz_result: NewQuizResult) => {
      this.reset();
      this.quiz_id = new_quiz_result.quiz_id;
      this.total = new_quiz_result.total;
      this.quiz_mode = "start";
      this.current_vocabulary = new_quiz_result.first;
    });
  }

  mounted(): void {
    const current_route: Route = this.nav_router.getCurrentNavigatable() as Route;
    this.nav_router.pushModuleLifecycleEvent({module: this.module_type, event: VUE_LIFECYCLE_EVENT.MOUNTED});
  }

  reset(): void {
      this.current_vocabulary = null;
      this.next_vocabulary = null;
      this.entry_verdict = null;
      //public audio_first: boolean = false;
      //public reverse_mode: boolean = false;
      this.loading_next_entry = false;
      this.quiz_summary = null;
      this.quiz_liebiaos = new Array<Liebiao>(); 
      this.quiz_tags = new Array<Tag>();
      this.recently_quized_loading = true;
      this.loadRecentlyQuizzed();
  }

  loadRecentlyQuizzed(): void {
    this.quiz_service.getRecentlyQuizzed().then( (recently_quizzed: Array<QuizSummaryEntryVerdict>) => {
      this.recently_quized_loading = false;
      this.recently_quizzed = recently_quizzed;
    });
  }

  created(): void {
    const current_route: Route = this.nav_router.getCurrentNavigatable() as Route;
    if(current_route.params && current_route.params.quiz_id) {
      this.quiz_mode = "loading";
      this.quiz_id = parseInt(current_route.params.quiz_id);
      this.quiz_service.resumeQuiz({quiz_id: this.quiz_id}).then( (quiz_resume_result: QuizResumeResult) => {
        this.total = quiz_resume_result.total;
        this.quiz_settings = quiz_resume_result.quiz_settings;
        if(quiz_resume_result.quiz_summary) {
          this.quiz_mode = "results";
          this.quiz_summary = quiz_resume_result.quiz_summary;
        } else {
          if(quiz_resume_result.entry) this.current_vocabulary = quiz_resume_result.entry;
          this.quiz_mode = "quiz";
          if(quiz_resume_result.quiz_index) {
            this.quiz_index = quiz_resume_result.quiz_index;
            if(this.quiz_index + 1 <= this.total) {
              this.loadEntry(this.quiz_index + 1);
            }
          }
        }
      }).catch( (error) => {
        console.log(error);
      });
    }
    //if we navigated backwards from a quiz
    this.nav_event_subscription = this.nav_router.nav_event$.pipe(
        filter( (from_to: NavFromTo) => {
            if(from_to.from.name == this.module_type && from_to.to.name == this.module_type) {
                return true;
            }
            return false;
        })
    ).subscribe({
        next: (from_to: NavFromTo) => {
            if(from_to.to.params.quiz_id == undefined) {
                //we clicked on "quiz" from the nav bar while we are in the middle of a quiz
                this.quiz_mode = "selection";
                this.quiz_id = 0;
                this.total = 0;
                this.quiz_index = 0;
                this.current_vocabulary = null;
                this.next_vocabulary=  null;
                this.quiz_settings.audio_mode = false;
                this.quiz_settings.reverse_mode = false;
            } else {
              console.log('quiz_id!');
            }
        }
    });
    //recently quizzed
    this.loadRecentlyQuizzed();
  }

  cleanUp(): void {
    this.nav_event_subscription?.unsubscribe();
  }

  destroyed(): void {
    this.cleanUp();
  }

  unmounted(): void {
    this.cleanUp();
  }

/*
  activated(): void {
    console.log('vocabulary view is activated');
    this.nav_router.pushModuleLifecycleEvent({module: SHENGCI_MODULES.VOCABULARY, event: VUE_LIFECYCLE_EVENT.ACTIVATED});
  }
  */
}
