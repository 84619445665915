



































































































































import { Component, Prop, Emit, Vue, PropSync, Watch } from 'vue-property-decorator';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { TagBoss } from '@/services';
import { AppStage, SearchMode, SearchParams, Vocabulary, QuizEntry, QuizSettings } from '@/types';
import AppMain from '@/AppMain'
import { take, first } from 'rxjs/operators';
import AudioButton from '@/components/shared/AudioButton.vue';
import TagChip from '@/components/shared/TagChip.vue';
import { log } from '@/helpers/logger';

type SVocabulary = Vocabulary;
type SQuizEntry = QuizEntry;
type SQuizSettings = QuizSettings;

@Component({
  components: {
    AudioButton, TagChip
  }
})
export default class QuizEntryCard extends Vue
{
    @lazyInject(TYPES.TAGBOSS_INSTANCE)
    private tagboss!: TagBoss;
  
    @Prop() quiz_entry!: SQuizEntry;
    @Prop({default: 0}) quiz_index!: number;
    @Prop({default: 0}) quiz_total!: number;
    public finished: boolean = false;
    public show_note: boolean = false;
    public show_tags: boolean = false;
    @Prop() quiz_settings!: SQuizSettings;
    public show_head: boolean = false;
    public show_pinyin: boolean = false;
    public show_english: boolean = false;
    public next_entry_loading: boolean = false;
    public verdict: boolean = false;

    @Watch('quiz_entry')
    quizEntryChanged(): void {
      this.init();
    }

    @Emit('nextVocabulary')
    nextVocabulary(result: boolean): boolean {
      this.verdict = result;
      this.next_entry_loading = true;
      return result;
    }

    lastEntry(): boolean {
      return this.quiz_index == this.quiz_total;
    }

    nextStep(): void {
      if(this.quiz_settings.reverse_mode) {
        this.show_head = true;
        this.show_pinyin = true;
        this.finished = true;
      } else {
        if(!this.show_head) { this.show_head = true; return; }
        if(!this.show_pinyin) { this.show_pinyin = true; return; }
        if(!this.show_english) {
          this.show_english = true;
          this.finished = true;
          return;
        }
      }
    }

    pinyin(): string {
      return this.quiz_entry.vocabulary.pinyin.length > 0 ? this.quiz_entry.vocabulary.pinyin : "No Pinyin";
    }

    init(): void {
      if(this.quiz_settings.reverse_mode) {
        this.show_english = true;
        this.show_head = false;
        this.show_pinyin = false;
      } else {
        if(this.quiz_settings.audio_mode && this.quiz_entry.vocabulary.audio_file && !this.quiz_entry.vocabulary.audio_file.isEmpty()) {
          this.show_head = false;
          this.$nextTick(() => {
            if(this.$refs.audio_button as AudioButton) {
              (this.$refs.audio_button as AudioButton).playOrStopAudio();
            }
          });
        } else {
          this.show_head = true;
        }
        
        this.show_pinyin = false;
        this.show_english = false;
      }
      this.finished = false;
      this.show_note = false;
      this.show_tags = false;
      this.verdict = false;
      this.next_entry_loading = false;
    }

    created(): void {
      this.init();
      //wait till we're logged and app is ready before loading tags
      const app_stage_events$ = AppMain.app_stage_events$.pipe(first((app_stage: AppStage)=>app_stage=="authenticated"),take(1)).subscribe({
        next: (app_stage: AppStage) => {
          console.log();
        }
      });
    }
}
